import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { ICenarioFiscal, ICenarioFiscalDefinicao } from '@/models/Entidades/Cadastros/Tributacoes/CenariosFiscais/ICenarioFiscal';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { IParametrosConsultaDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Tributacoes/IParametrosConsultaDefinicao';
import { ETipoImposto } from '@/models/Enumeradores/Cadastros/Tributacoes/CenariosFiscais/ETipoImposto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOCenarioFiscalCopiarDefinicoes } from '@/models/DTO/Cadastros/Tributacoes/CenariosFiscais/IDTOCenarioFiscalCopiarDefinicoes';
import { IParametrosConsultaDefinicaoMovimentacao } from '@/models/ParametrosRequisicao/Cadastros/Tributacoes/IParametrosConsultaDefinicaoMovimentacao';
import { IDTOCenarioFiscalDefinicaoMovimentacao } from '@/models/DTO/Tributacoes/IDTOCenarioFiscalDefinicaoMovimentacao';

/**
 * Serviço de Cenários fiscais
 * Fornece todas regras de negócios e lógicas relacionado a Cenários fiscais.
 */

class ServicoCenarioFiscal implements IServicoBase<ICenarioFiscal> {
    endPoint = 'cenarios-fiscais';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterListaOrigemMercadorias(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/origem-mercadorias`);
      return result.data;
    }

    public async obterListaTiposCalculosImpostos(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/tipos-calculo-impostos`);
      return result.data;
    }

    public async obterListaModalideBaseCalculoICMS(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/modalidades-base-calculo-icms`);
      return result.data;
    }

    public async obterListaModalideBaseCalculoICMSSubstituicao(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/modalidades-base-calculo-icms-substituicao`);
      return result.data;
    }

    public async obterListaCstIcms(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/cst-icms`);
      return result.data;
    }

    public async obterListaCsosn(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/csosn-icms`);
      return result.data;
    }

    public async obterListaCstIpi(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/cst-ipi`);
      return result.data;
    }

    public async obterListaCstIpiSaidas(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/cst-ipi-saidas`);
      return result.data;
    }

    public async obterListaCstIpiEntradas(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/cst-ipi-entradas`);
      return result.data;
    }

    public async obterListaPisCofins(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/cst-pis-cofins`);
      return result.data;
    }

    public async obterListaPisCofinsSaidas(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/cst-pis-cofins-saidas`);
      return result.data;
    }

    public async obterListaPisCofinsEntradas(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/cst-pis-cofins-entradas`);
      return result.data;
    }

    public async obterListaCodigosEnquadramentoIpi(): Promise<IItemGenerico[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/codigos-enquadramento-ipi`);
      return result.data;
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public async relatorioDetalhado(codigo: number): Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/relatorio-detalhado`);
      return result.data;
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: ICenarioFiscal): string {
      return `${objeto.nome}`;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida/tipo-movimento/0${parametrosAdicionais}`);
      return result.data;
    }

    public async consultaRapidaAvancada(parametrosConsultaRapida: IParametrosConsultaRapida, tipoMovimento: number): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida/tipo-movimento/${tipoMovimento}${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(objeto: ICenarioFiscal): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
      return result.data;
    }

    public async alterar(objeto: ICenarioFiscal): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
      return result.data;
    }

    public async obter(codigo: number): Promise<ICenarioFiscal> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async validarCstSubstituicao(cst:string): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/validacoes/cst-substituicao/${cst}`);
      return result.data;
    }

    public async incluirDefinicao(codigoEmpresa:number, objeto: ICenarioFiscalDefinicao): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/definicoes/empresa/${codigoEmpresa}`, objeto);
      return result.data;
    }

    public async obterDefinicao(codigo: number, codigoEmpresa:number): Promise<ICenarioFiscalDefinicao> {
      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes/${codigo}/empresa/${codigoEmpresa}`);
      return result.data;
    }

    public async obterTodasDefinicoes(codigo: number, empresa:number): Promise<ICenarioFiscalDefinicao[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/obter-definicoes/${codigo}/${empresa}`);
      return result.data;
    }

    public async obterResumoDefinicoes(codigoCenarioFiscal: number, tipoImposto:ETipoImposto, parametrosConsulta: IParametrosConsulta, pesquisarValor?:string): Promise<IListaPaginada> {
      let parametrosAdicionais = `?TipoImposto=${tipoImposto}`;
      parametrosAdicionais += this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta);
      if (pesquisarValor !== undefined) {
        if (UtilitarioGeral.valorValido(pesquisarValor)) {
          parametrosAdicionais += `&PesquisarValor=${pesquisarValor}`;
        }
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoCenarioFiscal}/definicoes/resumo${parametrosAdicionais}`);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data;
      return listaPaginada;
    }

    public async obterDefinicoes(codigoCenarioFiscal: number, paramestrosConsulta: IParametrosConsultaDefinicao): Promise<ICenarioFiscalDefinicao[]> {
      let parametrosAdicionais = '';

      parametrosAdicionais += `?tipoImposto=${paramestrosConsulta.tipoImposto}`;
      parametrosAdicionais += `&tipoItem=${paramestrosConsulta.tipoItem}`;
      parametrosAdicionais += `&buscaResumida=${paramestrosConsulta.buscaResumida}`;
      if (paramestrosConsulta.codigoItemSelecionado > 0) {
        parametrosAdicionais += `&codigoItemSelecionado=${paramestrosConsulta.codigoItemSelecionado}`;
      }
      if (paramestrosConsulta.estado > 0) {
        parametrosAdicionais += `&estado=${paramestrosConsulta.estado}`;
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoCenarioFiscal}/definicoes${parametrosAdicionais}`);
      return result.data;
    }

    public async obterDefinicoesMovimentacao(paramestrosConsulta: IParametrosConsultaDefinicaoMovimentacao): Promise<IDTOCenarioFiscalDefinicaoMovimentacao> {
      let parametrosAdicionais = '';
      parametrosAdicionais += `?codigoEmpresa=${paramestrosConsulta.codigoEmpresa}`;
      parametrosAdicionais += `&codigoCenarioFiscal=${paramestrosConsulta.codigoCenarioFiscal}`;
      parametrosAdicionais += `&tipoMovimentoComercial=${paramestrosConsulta.tipoMovimentoComercial}`;
      parametrosAdicionais += `&codigoProdutoDefinicao=${paramestrosConsulta.codigoProdutoDefinicao}`;
      parametrosAdicionais += `&codigoNcm=${paramestrosConsulta.codigoNcm}`;
      parametrosAdicionais += `&origemMercadoria=${paramestrosConsulta.origemMercadoria}`;
      parametrosAdicionais += `&codigoEstado=${paramestrosConsulta.codigoEstado}`;
      parametrosAdicionais += `&tipoAtividade=${paramestrosConsulta.tipoAtividade}`;
      parametrosAdicionais += `&tipoIe=${paramestrosConsulta.tipoIe}`;
      parametrosAdicionais += `&pessoaSuperSimples=${paramestrosConsulta.pessoaSuperSimples}`;

      const result: any = await this.apiERP.get(`${this.endPoint}/definicoes/movimentacoes${parametrosAdicionais}`);
      return result.data;
    }

    public async obterDefinicaoPisPadrao(codigoCenarioFiscal: number): Promise<ICenarioFiscalDefinicao> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoCenarioFiscal}/definicoes/pis/padrao`);
      return result.data;
    }

    public async obterDefinicaoCofinsPadrao(codigoCenarioFiscal: number): Promise<ICenarioFiscalDefinicao> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigoCenarioFiscal}/definicoes/cofins/padrao`);
      return result.data;
    }

    public async alterarDefinicao(codigoDefinicao:number, codigoEmpresa:number, objeto: ICenarioFiscalDefinicao): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/definicoes/${codigoDefinicao}/empresa/${codigoEmpresa}`, objeto);
      return result.data;
    }

    public async excluirDefinicao(codigoEmpresa:number, codigosDefinicoes:number[]): Promise<IRetornoRequisicao> {
      let parametrosAdicionais = '';
      codigosDefinicoes.forEach((codigoDefinicao) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `codigos=${codigoDefinicao}`;
      });

      const result: any = await this.apiERP.delete(`${this.endPoint}/definicoes/empresa/${codigoEmpresa}${parametrosAdicionais}`);
      return result.data;
    }

    public async copiarDefinicoes(objeto:IDTOCenarioFiscalCopiarDefinicoes): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/definicoes/copiar`, objeto);
      return result.data;
    }
}
export default ServicoCenarioFiscal;
