import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IEstoque, IEstoqueTipoProduto } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { IEstoqueItem } from '@/models/Entidades/Cadastros/Estoques/IEstoqueItem';
import { IDTOEstoque } from '@/models/DTO/Cadastros/Estoques/IDTOEstoque';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOConsultaEstoque } from '@/models/DTO/Estoques/IDTOConsultaEstoque';
import { ETipoMovimentoEstoque } from '@/models/Enumeradores/Estoques/MovimentoEstoque/ETipoMovimentoEstoque';
import { DTOMovimentoComercialItem } from '@/models/DTO/MovimentosComerciais/DTOMovimentoComercialItem';
import { IParametrosConsultaEstoque } from '@/models/ParametrosRequisicao/Estoques/IParametrosConsultaEstoque';
import { IDTOEstoqueProdutoDefinicao } from '@/models/DTO/Cadastros/Estoques/IDTOEstoqueProdutoDefinicao';
import { IDTOProdutoEstoqueMovimento } from '@/models/DTO/Cadastros/Estoques/IDTOProdutoEstoqueMovimento';
import { IDTOPosicaoEstoque } from '@/models/DTO/Estoques/IDTOPosicaoEstoque';

/**
 * Serviço de Estoques
 * Fornece todas regras de negócios e lógicas relacionado a Estoques.
 */

class ServicoEstoque implements IServicoBase<IEstoque> {
  endPoint = 'estoques';

  apiERP = new ApiERP();

  public requisicaoSistema(): void {
    this.apiERP = new ApiERP(true);
  }

  public async obterTodosEstoques(empresa: number): Promise<IEstoque[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/todos/${empresa}`);
    return result.data;
  }

  public async obterListaTipoEstoque(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipos`);
    return result.data;
  }

  public async validarUsoMultiEstoques(empresa: number, tipoMovimento: ETipoMovimentoEstoque): Promise<boolean> {
    const result: any = await this.apiERP.get(`${this.endPoint}/uso-multi-estoques/empresa/${empresa}/tipo-movimento/${tipoMovimento}`);
    return result.data;
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
    return listaPropriedades;
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOEstoque): string {
    return objeto.descricao;
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaEstoque): Promise<IItemConsultaRapida[]> {
    const parametros = parametrosConsultaRapida;

    parametros.filtrarTextoExato = false;

    if (parametrosConsultaRapida.recursoAssociado === null || parametrosConsultaRapida.recursoAssociado === undefined) {
      parametros.recursoAssociado = '';
    }

    if (parametrosConsultaRapida.filtrarTodosEstoques === null || parametrosConsultaRapida.filtrarTodosEstoques === undefined) {
      parametros.filtrarTodosEstoques = false;
    }

    if (parametrosConsultaRapida.quantidadeRegistros === null || parametrosConsultaRapida.quantidadeRegistros === undefined) {
      parametros.quantidadeRegistros = 10;
    }

    if (!UtilitarioGeral.validaLista(parametrosConsultaRapida.empresas)) {
      parametros.empresas = [] as number[];
    }

    if (!UtilitarioGeral.validaLista(parametrosConsultaRapida.estoques)) {
      parametros.estoques = [] as number[];
    }

    if (!UtilitarioGeral.validaLista(parametrosConsultaRapida.utilizadores)) {
      parametros.utilizadores = [] as number[];
    }

    if (!UtilitarioGeral.validaLista(parametrosConsultaRapida.tiposProdutos)) {
      parametros.tiposProdutos = [] as number[];
    }

    if (!UtilitarioGeral.validaLista(parametrosConsultaRapida.produtosDefinicoes)) {
      parametros.produtosDefinicoes = [] as number[];
    }

    if (!UtilitarioGeral.validaLista(parametrosConsultaRapida.unidades)) {
      parametros.unidades = [] as number[];
    }

    if (!UtilitarioGeral.validaLista(parametrosConsultaRapida.tiposEstoque)) {
      parametros.tiposEstoque = [] as number[];
    }

    const result: any = await this.apiERP.post(`${this.endPoint}/consulta-rapida`, parametros);
    return result.data;
  }

  public async obterCodigoEstoquePadrao(item: DTOMovimentoComercialItem): Promise<number> {
    const result: any = await this.apiERP.post(`${this.endPoint}/estoque-padrao`, item);
    return result.data;
  }

  public async obterCodigoItemEstoquePadrao(item: DTOMovimentoComercialItem): Promise<number> {
    const result: any = await this.apiERP.post(`${this.endPoint}/item-estoque-padrao`, item);
    return result.data;
  }

  public async obterEstoquesProdutoMovimento(codigoProdutoDefinicao: number, empresas:number[], movimentarEntrada: boolean, movimentarSaida: boolean): Promise<IDTOProdutoEstoqueMovimento[]> {
    let parametrosAdicionais = '';

    if (UtilitarioGeral.validaLista(empresas)) {
      empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `empresas=${codigoEmpresa}`;
      });
    }

    if (UtilitarioGeral.validaBoleano(movimentarEntrada)) {
      parametrosAdicionais += `&movimentarEntrada=${movimentarEntrada}`;
    }

    if (UtilitarioGeral.validaBoleano(movimentarSaida)) {
      parametrosAdicionais += `&movimentarSaida=${movimentarSaida}`;
    }
    const result: any = await this.apiERP.get(`${this.endPoint}/itens/produto/${codigoProdutoDefinicao}${parametrosAdicionais}`);
    return result.data;
  }

  public async incluir(objeto: IEstoque): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}`, objeto);
    return result.data;
  }

  public async alterar(objeto: IEstoque): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}`, objeto);
    return result.data;
  }

  public async obter(codigo: number, empresa: number): Promise<IEstoque> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
    return result.data;
  }

  public async obterTiposProdutos(codigo: number): Promise<IEstoqueTipoProduto[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/tipos-produtos`);
    return result.data;
  }

  public async obterEstruturaOrganizacional(codigo: number): Promise<string> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/estrutura-organizacional`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async incluirItens(codigoEstoque: number, itens: IEstoqueItem[]): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/${codigoEstoque}/itens`, itens);
    return result.data;
  }

  public async obterItem(codigoItem: number, empresa: number): Promise<IEstoqueItem> {
    const result: any = await this.apiERP.get(`${this.endPoint}/codigoItem/${codigoItem}/empresa/${empresa}`);
    return result.data;
  }

  public async obterItens(codigoEstoque: number, codigoDefinicoesProdutos?: number[]): Promise<IEstoqueItem[]> {
    let parametrosAdicionais = '';
    if (codigoDefinicoesProdutos !== undefined) {
      codigoDefinicoesProdutos.forEach((codigoDefinicao) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `codigoDefinicoesProdutos=${codigoDefinicao}`;
      });
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/${codigoEstoque}/itens${parametrosAdicionais}`);
    return result.data;
  }

  public async obterItemEstoqueInformacoesProduto(codigoEstoque: number, codigoProdutoDefinicao: number, codigoUnidade: number, codigoEmpresa: number): Promise<IEstoqueItem> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigoEstoque}/itens/produto/${codigoProdutoDefinicao}/unidade/${codigoUnidade}/empresa/${codigoEmpresa}`);
    return result.data;
  }

  public async obterQuantidadeDisponivelItemEstoque(codigoItemEstoque: number): Promise<number> {
    const result: any = await this.apiERP.get(`${this.endPoint}/itens/${codigoItemEstoque}/quantidade-disponivel`);
    return result.data;
  }

  public async obterEstoquesDisponiveis(empresa: number, pessoa: number, tipoMovimento: ETipoMovimentoEstoque): Promise<IEstoque[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/todos/empresa/${empresa}/pessoa/${pessoa}/tipo-movimento/${tipoMovimento}`);
    return result.data;
  }

  public async obterEstoquesDefinicaoProduto(produtoDefinicao: number, empresa: number): Promise<IDTOEstoqueProdutoDefinicao[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/produto-definicao/${produtoDefinicao}/empresa/${empresa}`);
    return result.data;
  }

  public montaApresentacaoEstoque(estoque: IEstoque): string {
    if (estoque.codigoEstoquePai > 0) {
      return `${estoque.estruturaOrganizacional}.${estoque.sigla} - ${estoque.descricao}`;
    }

    if (UtilitarioGeral.valorValido(estoque.sigla)) {
      return `${estoque.sigla} - ${estoque.descricao}`;
    }

    return estoque.descricao;
  }

  public async consultaEstoquesComProdutos(parametrosConsulta: IParametrosConsultaEstoque): Promise<IDTOConsultaEstoque[]> {
    const result: any = await this.apiERP.post(`${this.endPoint}/consulta-estoque`, parametrosConsulta);
    return result.data;
  }

  public async consultaPosicaoEstoque(filtro: string, empresas?: number[]): Promise<IDTOPosicaoEstoque[]> {
    const result: any = await this.apiERP.post(`${this.endPoint}/posicao-estoque/${filtro}`, empresas);
    return result.data;
  }

  public async RelatorioPosicaoEstoque(tipoArquivo: ETipoArquivo, filtro: string, empresas?: number[]): Promise<IRetornoRelatorio> {
    const result: any = await this.apiERP.post(`${this.endPoint}/relatorio-posicao-estoque/${filtro}/tipo-arquivo/${tipoArquivo}`, empresas);
    return result.data;
  }
}
export default ServicoEstoque;
